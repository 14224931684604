import React from "react";
import LiquidityPools from "../components/Liquidity/LiquidityPools";

const LiquidityPage = () => {
  return (
    <div>
      <LiquidityPools />
    </div>
  );
};

export default LiquidityPage;
